import React from 'react';
import estorboImg from '../../../../assets/img/estorbo.png';
import backgroundImg from '../../../../assets/img/home-careers-background.png';
import backgroundMobileImg from '../../../../assets/img/home-careers-background-m.png';
import './footer.sass';

const Footer = () => {
  return (
    <section id="roles" className="footer-section">
      <div className="img-section">
        <img loading="lazy" className="background-img-m" src={backgroundMobileImg} alt=""/>
        <img loading="lazy" className="background-img" src={backgroundImg} alt=""/>
        <img loading="lazy" className="estorbo-img" src={estorboImg} alt="" />
        <div className="white-container">
          <p className="white-text">Join the movement!</p>
          <a href="https://chiper.bamboohr.com/jobs/">
            <button className="white-button">Explore Roles</button>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer;
