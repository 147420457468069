import React from 'react';
import teamImg from '../../../../assets/img/careers/team.png';
import planImg from '../../../../assets/img/careers/plan.png';
import remoteImg from '../../../../assets/img/careers/remote.png';
import birthdayImg from '../../../../assets/img/careers/birthday.png';
import investmentImg from '../../../../assets/img/careers/investment.png';
import medicineImg from '../../../../assets/img/careers/medicine.png';
import computerImg from '../../../../assets/img/careers/computer.png';
import lifeImg from '../../../../assets/img/careers/life.png';
import './propels.sass';

const Propels = () => {
  return (
    <section id="offer" className="propels-section">
      <div className="container">
        <p className="propels-title">What propels us</p>
        <p className="propels-subtitle">We are moved by passion and a founder’s mentality. We are fearless individuals with desire, hunger and thirst to get ahead, obtain more knowledge and continuously evolve.</p>
        <p className="propels-step">What do <span className="bold">we offer?</span></p>
        <div className="propels-container">
          <div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={teamImg} alt=""/>
              <p className="text-card">A diverse team made of extraordinary people </p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={planImg} alt=""/>
              <p className="text-card">A career development plan </p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={remoteImg} alt=""/>
              <p className="text-card">100% remote work from anywhere in the world</p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={birthdayImg} alt=""/>
              <p className="text-card">Day-off on your birthday </p>
            </div>
          </div>
          <div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={investmentImg} alt=""/>
              <p className="text-card">Investment plan for your own learning </p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={medicineImg} alt=""/>
              <p className="text-card">Medical plan</p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={computerImg} alt=""/>
              <p className="text-card">Computer and internet stipend </p>
            </div>
            <div className="item-card">
              <img loading="lazy" className="image-card" src={lifeImg} alt=""/>
              <p className="text-card">Life Insurance</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Propels;
