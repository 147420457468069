import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import CloseImg from "../../assets/img/close.svg";
import LogoImg from "../../assets/img/logo.png";
import LogoCareersImg from "../../assets/img/logo-careers.png";
import MobileMenuImg from "../../assets/img/mobile-menu-icon.svg";

import "./header.sass";

const Header = () => {
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isCareers = pathname?.includes("careers");

  const handleClick = () => {
    setShowMobileMenu(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="main-header">
      <div className="header-left-section">
        <img loading="lazy" className={isCareers ? "header-img careers" : "header-img"} src={isCareers ? LogoCareersImg : LogoImg} alt="" />
      </div>
      <div className="header-right-section">
        <NavLink
          exact="true"
          to="/"
          className="header-text"
          activeclassname="active"
        >
          About
        </NavLink>
        <NavLink
          exact="true"
          to="/careers"
          className="header-text"
          activeclassname="active"
        >
          Careers
        </NavLink>
        {isCareers && (
          <a target="_blank" rel="noopener noreferrer" href="https://chiper.bamboohr.com/jobs/">
            <button className="header-button">Roles</button>
          </a>
        )}
      </div>
      <div className="mobile-menu-icon" onClick={() => setShowMobileMenu(true)}>
        <img loading="lazy" className="icon" src={MobileMenuImg} alt="Mobile menu" />
      </div>
      <div className={`mobile-menu ${showMobileMenu ? 'show' : ''}`}>
        <div className="overlay" onClick={() => setShowMobileMenu(false)}></div>
        <div className="modal">
          <div className="close" onClick={() => setShowMobileMenu(false)}>
            <img loading="lazy" src={CloseImg} alt="close" />
          </div>
          <NavLink
            exact="true"
            to="/"
            className="header-text"
            activeclassname="active"
            onClick={handleClick}
          >
            About
          </NavLink>
          <NavLink
            exact="true"
            to="/careers"
            className="header-text"
            activeclassname="active"
            onClick={handleClick}
          >
            Careers
          </NavLink>
          {isCareers && (
            <a target="_blank" rel="noopener noreferrer" href="https://chiper.bamboohr.com/jobs/">
              <button className="header-button">Roles</button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header;
