import React from 'react';
import estorboImg from '../../../../assets/img/estorbo.png';
import backgroundImg from '../../../../assets/img/careers/careers-background-m.png';
import './hero.sass';

const Hero = () => {
  return (
    <section id="careers" className="hero-section">
      <img loading="lazy" className="estorbo-img" src={estorboImg} alt="Estorbo" />
      <div className="main-text">
        <p className="text">
          Empowering corner stores in Latam
          <p className="sub-text">What’s your next career move?</p>
        </p>
      </div>
      <div className="background-white-m">
        <a className="decoration" href="https://chiper.bamboohr.com/jobs/">
          <button className="info-button">Explore roles</button>
        </a>
      </div>
      <img loading="lazy" className="background-m" src={backgroundImg} alt=""/>
    </section>
  )
}

export default Hero;
