import React from 'react';
import estorboImg from '../../../../assets/img/estorbo.png';
import backgroundImg from '../../../../assets/img/careers/testimonials-background.png';
import backgroundMobileImg from '../../../../assets/img/careers/testimonials-background-m.png';
import testimonyOneImg from '../../../../assets/img/careers/testimony-1.png';
import testimonyTwoImg from '../../../../assets/img/careers/testimony-2.png';
import testimonytTrheeImg from '../../../../assets/img/careers/testimony-3.png';
import testimonyFourImg from '../../../../assets/img/careers/testimony-4.png';
import quotesImg from '../../../../assets/img/careers/quotes.png';
import './testimonials.sass';

const Testimonials = () => {
  const TestimonialsCard = ({
    image,
    textTestimonial,
    name,
    since,
    country,
    isFirst
  }) => (
    <div className={isFirst ? "testimonial-card margin" : "testimonial-card"}>
      <img loading="lazy" className="card-img" src={image} alt="" />
      <div className="quotes-section">
        <img loading="lazy" className="quotes-img" src={quotesImg} alt="" />
        <p className="testimonial-text">{textTestimonial}</p>
      </div>
      <div className="personal-card">
        <p className="name">{name}</p>
        <p className="since">{since}</p>
        <p className="since">{country}</p>
      </div>
    </div>
  )
  return (
    <section id="testimonials" className="testimonials-section">
      <div className="img-section">
        <img loading="lazy" className="testimonals-img" src={backgroundImg} alt="" />
        <img loading="lazy" className="testimonals-img-m" src={backgroundMobileImg} alt="" />
        <img loading="lazy" className="estorbo-img" src={estorboImg} alt="" />
        <div className="white-card"/>
      </div>
      <div className="testimonials">
        <div className="testimonial-left-section">
          <p className="testimonial-title">Testimonials</p>
          <p className="testimonal-subtitle">What makes us proud of Chiper?</p>
        </div>
        <div className="testimonal-right-section">
          <TestimonialsCard
            image={testimonyOneImg}
            textTestimonial="Chiper es el impulso, la energía y la chispa que detonan y aceleran mis días. Cada reto es una vorágine de aprendizaje, donde las metas se alcanzan contando con el mejor equipo, que vive y respira al unísono, dentro y fuera de un gigantesco engrane que clama compromiso para triunfar"
            name="Alejandro Terán"
            since="At Chiper since 2020"
            country="México"
            isFirst
          />
          <TestimonialsCard
            image={testimonyTwoImg}
            textTestimonial="Trabajar en Chiper, es aceptar el gran reto de crecer junto a una compañía que esta en constante cambio y en constante evolución; es permitirse aportar toda la pasión que requiere la labor dinámica de una Startup"
            name="Paola Figueroa"
            since="At Chiper since 2021"
            country="México"
          />
          <TestimonialsCard
            image={testimonytTrheeImg}
            textTestimonial="Working at Chiper means accomplishing my dreams as a professional, with the opportunity to build a future for my family"
            name="Tatiana Tarquino"
            since="At Chiper since 2018"
            country="Colombia"
          />
          <TestimonialsCard
            image={testimonyFourImg}
            textTestimonial="Trabalhar na Chiper é estar constantemente diante do desafio. Você é incentivado a evoluir e melhorar, seja tecnicamente e até aprendendo um novo idioma. O melhor de tudo é ter a liberdade de fazer seu trabalho de onde você quiser!"
            name="Glauber Holanda"
            since="At Chiper since 2021"
            country="Brasil"
          />
        </div>
      </div>
    </section>
  )
}

export default Testimonials;
