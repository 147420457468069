import { useEffect } from "react";
import { Link } from "react-router-dom";
import './home.sass';
import homeBackboneImg from '../../assets/img/home-backbone.png';
import homeBackboneCheckImg from '../../assets/img/home-backbone-check.svg';
import cornerStoresExplainImg from '../../assets/img/home-corner-stores-explain.png';
import tenderImg from '../../assets/img/home-tender-1.png';
import estorboImg from '../../assets/img/estorbo.png';
import hdwdiTruckImg from '../../assets/img/how-do-we-do-it-delivery-truck.svg';
import hdwdiCashImg from '../../assets/img/how-do-we-do-it-cash.svg';
import hdwdiEcommerceImg from '../../assets/img/how-do-we-do-it-ecommerce.svg';

const Home = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.effect');
    const options = {
      rootMargin: '0px',
      threshold: 0
    }
    const callback = (entries) => {
      entries.forEach((entry) => {
      const target = entry.target;
        if (entry.isIntersecting) {
          target.classList.add("animate");
        }
      })
    }
    const observer = new IntersectionObserver(callback, options)
    sections.forEach((section) => {
      observer.observe(section);
    });
  }, []);
  return (
    <>
      <div className="wrapper-home">
        <section className="hero-page">
          <img loading="lazy" className="tender-img" src={tenderImg} alt="Tender one" />
          <img loading="lazy" className="estorbo-img" src={estorboImg} alt="Estorbo" />
          <div className="main-text">
            <p className="text effect">
              Chiper is the leading B2B
              e-commerce ecosystem for corner stores in Latin America
            </p>
          </div>
        </section>
        <section className="shops-data">
          <div className="title effect">The One-stop-shop for store's needs:</div>
          <div className="grid">
            <div className="item">
              <div className="texts">
                <div className="value">40k+</div>
                <div className="name">Corner stores</div>
              </div>
            </div>
            <div className="item">
              <div className="texts">
                <div className="value">2</div>
                <div className="name">Countries</div>
              </div>
            </div>
            <div className="item">
              <div className="texts">
                <div className="value">5</div>
                <div className="name">Cities</div>
              </div>
            </div>
            <div className="item">
              <div className="texts">
                <div className="value">3k</div>
                <div className="name">Products</div>
              </div>
            </div>
            <div className="item">
              <div className="texts">
                <div className="value">400+</div>
                <div className="name">Brands</div>
              </div>
            </div>
          </div>
        </section>
        <section className="backbone">
          <div className="content">
            <div className="title effect">Corner stores are the backbone of Latam’s everyday grocery supply</div>
            <div className="explain">
              <img loading="lazy" className="backbone-img effect leftToRight" src={homeBackboneImg} alt="Backbone" />
              <div className="texts">
                <div className="item">
                  <img src={homeBackboneCheckImg} alt="check" className="check" />
                  <span className="text">Preferred for convenient locations</span>
                </div>
                <div className="item">
                  <img src={homeBackboneCheckImg} alt="check" className="check" />
                  <span className="text">Understand local consumers needs</span>
                </div>
                <div className="item">
                  <img src={homeBackboneCheckImg} alt="check" className="check" />
                  <span className="text">Offer informal flexible payment options for local shoppers</span>
                </div>
                <div className="item">
                  <img src={homeBackboneCheckImg} alt="check" className="check" />
                  <span className="text">Provide a vast selection of small-packaged goods</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="corner-stores-explain">
          <img src={cornerStoresExplainImg} alt="Corner stores explain" className="img effect leftToRight" />
          <div className="texts">
            <div className="main-text">
              While there are 3.7 million corner stores with a TAM of $500B, less than 1% of B2B purchases are done online. 
            </div>
            <div className="description">
              Leaving corner store owners  stuck replenishing their businesses the same way they have since the 20th century
            </div>
          </div>
        </section>
        <section id="how-do-we-do-it" className="how-do-we-do-it">
          <div className="texts">
            <div className="title">
              How <strong className="bold">do we do it?</strong>
            </div>
            <div className="main-title">
              With Chiper, corner store owners can replenish their store’s inventory through a complete digital experience.
            </div>
          </div>
          <div className="items">
            <div className="item">
              <img loading="lazy" className="icon" src={hdwdiTruckImg} alt="Truck" />
              <div className="item-texts">
                <div className="name">Unrivaled Delivery Speed</div>
                <div className="description">
                  Solving customer’s needs through the fastest order delivery available on the market
                </div>
              </div>
            </div>
            <div className="item">
              <img loading="lazy" className="icon" src={hdwdiEcommerceImg} alt="Truck" />
              <div className="item-texts">
                <div className="name">Modernizing Procurement</div>
                <div className="description">
                Supporting customer needs, through a carefully curated product portfolio, offering the widest selection on one platform
                </div>
              </div>
            </div>
            <div className="item">
              <img loading="lazy" className="icon" src={hdwdiCashImg} alt="Truck" />
              <div className="item-texts">
                <div className="name">Unbeatable Competitive Prices</div>
                <div className="description">
                Leveraging machine learning and automation increases our efficiencies, enabling higher margins, and creating opportunities to offer the most competitive prices 
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="careers">
          <img loading="lazy" className="estorbo-img" src={estorboImg} alt="Estorbo" />
          <div className="white-box">
            <div className="title">
              Careers<strong className="bold"> at Chiper</strong>
            </div>
            <div className="main-text">
              At Chiper, we are mission focused and customer obssesed.
            </div>
            <div className="description">
              Our team works relentlessly to grow a healthy and scalable company that will push society forward and we are always looking for the hungriest and most driven individuals out there to join us on this journey. 
            </div>
            <Link className="button" to="/careers">Explore 100+ Roles</Link>
          </div>
        </section>
      </div>
    </>
  );
}
 
export default Home;