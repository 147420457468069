import './footer.sass';

const Footer = () => {
  return (
    <div className="wrapper-footer">
      <span className="text">© {new Date().getFullYear()} - Chiper S.A.S</span>
    </div>
  );
}
 
export default Footer;