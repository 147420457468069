import React from 'react';
import './mission.sass';

const Mission = () => {
  return (
    <section id="mission" className="mission-section">
      <a href="https://chiper.bamboohr.com/jobs/">
        <button className="info-button">Explore roles</button>
      </a>
      <p className="info-text">Our <span className="bold">Mission</span></p>
      <p className="info-text title-text">At Chiper we are on a mission to empower corner store owners to earn more money and become more competitive as businesses.</p>
      <p className="info-text body-text">
        Unfortunately, corner store owners are stuck replenishing their store’s the same way they have for decades. The consequence of having to do business in a fragmented and opaque purchasing system, results in store owners spending excess time, labor and working capital.
        <p><br/>At Chiper, we have built a custom-tailored e-commerce ecosystem that offers the fastest delivery, widest selection, and most competitive prices.With Chiper, corner store owners are empowered to make smarter purchasing decisions, earn more and become more competitive as businesses.</p><br/>
        <p>Founded in 2018, we continue being a young company with a long journey ahead. We have set audacious goals so we can become essential for our customers, the B2B ecosystem, and continue raising the bar on service levels for the entire region. Our expansion plan is aggressive, so far we have operations in five cities across Mexico and Colombia, with the launch in Brazil coming soon.</p>
      </p>
    </section>
  )
}

export default Mission;
