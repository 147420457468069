import './careers.sass';
import Hero from './sections/hero/hero';
import Mission from './sections/mission/mission';
import Culture from './sections/culture/culture';
import Propels from './sections/propels/propels';
import Testimonials from './sections/testimonials/testimonials';
import Footer from './sections/footer/footer';

const Careers = () => {
  return (
    <>
      <div className="wrapper-careers">
        <Hero />
        <Mission />
        <Culture />
        <Propels />
        <Testimonials />
        <Footer />
      </div>
    </>
  );
}

export default Careers;
