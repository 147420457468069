import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from "./components/header/header";
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import Careers from './pages/careers/careers';

import './App.sass';

const App = () => {
  return (
    <div className="app">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
