import React from 'react';
import cultureImg from '../../../../assets/img/careers/culture-background.png';
import './culture.sass';

const Culture = () => {
  const CardCulture = ({
    number,
    title,
    text,
    isFirst,
    isRight
  }) => (
    <div className={isFirst ? "card-culture first-card" : isRight ? "card-culture left-card" : "card-culture"}>
      <p className="card-title">{number}</p>
      <div className="card-right-section">
        <p className="card-right-title">{title}</p>
        <p className="card-right-subtitle">{text}</p>
      </div>
    </div>
  )
  return (
    <section id="culture" className="culture-section">
      <img className="culture-img" loading="lazy" src={cultureImg} alt=""/>
      <div className="container-culture">
        <p className="culture-step">Our <span className="bold">Culture</span></p>
        <p className="culture-title">Purpose</p>
        <p className="culture-subtitle">Through the constant evolution of our clients, and our service, we foster the entrepreneurial spirit at the base of society.</p>
        <p className="culture-card-title">Principles</p>
        <CardCulture
          isFirst
          number="01"
          title="Our customers are always first"
          text="We do everything in our power to keep our promise to every customer and stakeholder. Our team works relentlessly to solve any problem that might put at risk our customer’s trust in us."
        />
      </div>
      <div className="container-culture left-container">
        <CardCulture
          number="02"
          title="We are extreme owners of what we do"
          text="We make sure things are done right. We organize so our teams are empowered to execute on their responsibilities. We create the conditions necessary to increase the speed at which we work. Learning to work smart, rather than just hard."
        />
      </div>
      <div className="container-culture">
        <CardCulture
          isRight
          number="03"
          title="We raise the bar in everything we do"
          text="We consistently challenge ourselves in everything we set out to do. We truly believe that it is possible to consistently improve by leveraging our creativity and that of our teams."
        />
      </div>
      <div className="container-culture left-container">
        <CardCulture
          number="04"
          title="We never stop training or learning"
          text="We are athletes and we demand only excellence of ourselves in order to improve our capabilities. We view every day as an opportunity to better prepare ourselves, take on new challenges that arise and be ready for when new opportunities arrive."
        />
      </div>
      <div className="container-culture">
        <CardCulture
          isRight
          number="05"
          title="We are missionaries with a common purpose"
          text="We do what we do, because we enjoy it, because we want to generate a positive impact in the world. We are here because we envision a better world and would like to add our grain of sand to this movement."
        />
      </div>
      <div className="container-culture left-container">
        <CardCulture
          number="06"
          title="We have a founder’s mentality"
          text="Like founders, it is imperative that our company can grow in a sustainable way. We invest aggressively in initiatives that we believe in and carefully use our capital and resources to be effective and efficient in our work."
        />
      </div>
      <div className="container-culture">
        <CardCulture
          isRight
          number="07"
          title="We do everything with design in mind"
          text="We design everything. Our team invests countless hours to developing profound understanding of our users in order to translate these into memorable experiences our customers have with every interaction."
        />
      </div>
    </section>
  )
}

export default Culture;
